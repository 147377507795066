export default {
    settings: {
        DASHBOARD_DAYS: 'DASHBOARD_DAYS',
        DASHBOARD_DAYS_SUCCESS: 'DASHBOARD_DAYS_SUCCESS',
        DASHBOARD_TOGGLE_DATEPICKER: 'DASHBOARD_TOGGLE_DATEPICKER',
        DASHBOARD_TOGGLE_DATEPICKER_SUCCESS: 'DASHBOARD_TOGGLE_DATEPICKER_SUCCESS'
    },
    store: {
        createSpot: {
            VALIDATE: "VALIDATE_CREATED_STORYSPOT",
            VALIDATE_FIELD: "VALIDATE_FIELD_CREATED_STORYSPOT",
            SET: "SET",
            SET_KEY_VALUE: "SET_KEY_VALUE",
            UPLOAD: "UPLOAD_STORYSPOT",
            UPLOAD_SUCCESS: "UPLOAD_STORYSPOT_SUCCESS",
            UPLOAD_ERROR: "UPLOAD_STORYSPOT_ERROR"
        },
        editSpot: {
            SET_BASE: "SET_BASE_EDIT",
            VALIDATE: "VALIDATE_EDIT_STORYSPOT",
            VALIDATE_FIELD: "VALIDATE_FIELD_EDIT_STORYSPOT",
            SET: "SET_EDIT",
            SET_KEY_VALUE: "SET_KEY_VALUE_EDIT",
            UPDATABLE_VALUES: "UPDATABLE_VALUES",
            UPDATE: "UPDATE_STORYSPOT",
            UPDATE_SUCCESS: "UPDATE_STORYSPOT_SUCCESS",
            UPDATE_ERROR: "UPDATE_STORYSPOT_ERROR"
        },
        profile: {
            SET: "SET_PROFILE",
            SET_KEY_VALUE: "SET_KEY_VALUE_PROFILE",
            VALIDATE_FIELD: "VALIDATE_FIELD_CREATED_PROFILE",
            UPDATABLE_VALUES: "UPDATEABLE_VALUES_PROFILE",
            SET_BASE: "SET_BASE_PROFILE",
            UPDATE: "UPDATE_PROFILE",
            UPDATE_ERROR: "UPDATE_PROFILE_ERROR",
            UPDATE_SUCCESS: "UPDATE_PROFILE_SUCCESS",
        },
        verifications: {
            FORGOT_PASSWORD_REQUEST: "FORGOT_PASSWORD_REQUEST",
            FORGOT_PASSWORD_SUCCESS: "FORGOT_PASSWORD_REQUEST_SUCCESS",
            FORGOT_PASSWORD_ERROR: "FORGOT_PASSWORD_REQUEST_ERROR",
            VERIFY_EMAIL_REQUEST: "VERIFY_EMAIL_REQUEST",
            VERIFY_EMAIL_SUCCESS: "VERIFY_EMAIL_SUCCESS",
            VERIFY_EMAIL_ERROR: "VERIFY_EMAIL_ERROR",
        },
        analytics: {
            SET_STATS: "SET_STATS",
            GET_STATS: "GET_STATS",
            GET_STATS_SUCCESS: "GET_STATS_SUCCESS",
            GET_STATS_ERROR: "GET_STATS_ERROR",
            GET_DID_SHOW_POPUP_REGISTRATION: "GET_DID_SHOW_POPUP_REGISTRATION",
            GET_DID_SHOW_POPUP_REGISTRATION_SUCCESS: "GET_DID_SHOW_POPUP_REGISTRATION_SUCCESS",
            GET_DID_SHOW_POPUP_REGISTRATION_ERROR: "GET_DID_SHOW_POPUP_REGISTRATION_ERROR",
            GET_DID_SHOW_POPUP_LOCAL_NOTIFICATION: "GET_DID_SHOW_POPUP_LOCAL_NOTIFICATION",
            GET_DID_SHOW_POPUP_LOCAL_NOTIFICATION_SUCCESS: "GET_DID_SHOW_POPUP_LOCAL_NOTIFICATION_SUCCESS",
            GET_DID_SHOW_POPUP_LOCAL_NOTIFICATION_ERROR: "GET_DID_SHOW_POPUP_LOCAL_NOTIFICATION_ERROR",
            CLEAR_DATA: "CLEAR_DATA",
            CLEAR_DATA_SUCCESS: "CLEAR_DATA_SUCCESS",
        },
        other: {
          SET_FEEDBACK: "SET_FEEDBACK_EMAIL",
          SEND_FEEDBACK: "SEND_FEEDBACK_EMAIL",
          SEND_FEEDBACK_SUCCESS: "SEND_FEEDBACK_EMAIL_SUCCESS",
          SEND_FEEDBACK_ERROR: "SEND_FEEDBACK_EMAIL_ERROR",
          SET_SUPPORT: "SET_SUPPORT",
          SEND_SUPPORT: "SEND_SUPPORT",
          SEND_SUPPORT_SUCCESS: "SEND_SUPPORT_SUCCESS",
          SEND_SUPPORT_ERROR: "SEND_SUPPORT_ERROR",
        },
        actions: {
            STATIC: {
              GET_STATIC_DATA_REQUEST: 'get_static_data_request',
              GET_STATIC_DATA_SUCCESS: 'get_static_Data_success',
              GET_STATIC_DATA_ERROR: 'get_static_Data_error',
              GET_RECOMMENDED_REQUEST: 'GET_RECOMMENDED_REQUEST',
              GET_RECOMMENDED_SUCCESS: 'GET_RECOMMENDED_SUCCESS',
              GET_RECOMMENDED_ERROR: 'GET_RECOMMENDED_ERROR',
                GET_DISCOVER_REQUEST: 'GET_DISCOVER_REQUEST',
                GET_DISCOVER_SUCCESS: 'GET_DISCOVER_SUCCESS',
                GET_DISCOVER_ERROR: 'GET_DISCOVER_ERROR',
            },
            AUTH: {
                GET_PROFILE: 'auth_get_profile',
                GET_PROFILE_SUCCESS: 'auth_get_profile_success',
                GET_PROFILE_ERROR: 'auth_get_profile_error',
                LOGIN_REQUEST: 'auth_login_request',
                LOGIN_SUCCESS: 'auth_login_request_success',
                LOGIN_ERROR: 'auth_login_request_error',
                LOGOUT_REQUEST: 'auth_logout_request',
                LOGOUT_SUCCESS: 'auth_logout_success',
                GOOGLE_LOGIN_REQUEST: "auth_google_login_request",
                APPLE_LOGIN_REQUEST: "auth_apple_login_request",
                AUTH_REGISTER_REQUEST: "auth_register_request",
                AUTH_REGISTER_SUCCESS: "auth_register_request_success",
                AUTH_REGISTER_ERROR: "auth_register_request_error"
            },
            STORYSPOT: {
                GET_USER_STORYSPOT_PLACE_REQUEST: 'GET_USER_STORYSPOT_PLACE_REQUEST',
                GET_USER_STORYSPOT_PLACE_SUCCESS: 'GET_USER_STORYSPOT_PLACE_SUCCESS',
                GET_USER_STORYSPOT_PLACE_ERROR: 'GET_USER_STORYSPOT_PLACE_ERROR',
                GET_USER_STORYSPOTS_REQUEST: 'get_user_storyspots_request',
                GET_USER_STORYSPOTS_SUCCESS: 'get_user_storyspots_success',
                GET_USER_STORYSPOTS_ERROR: 'get_user_storyspots_error',
                GET_STORYSPOT_DETAILED_REQUEST: 'get_storyspot_detailed_request',
                GET_STORYSPOT_DETAILED_SUCCESS: 'get_storyspots_detailed_success',
                GET_STORYSPOT_DETAILED_ERROR: 'get_storyspots_detailed_error',
                SET_SORT: 'set_storyspot_sort',
                GET_STORYSPOT_SEO_REQUEST: 'get_storyspot_seo_request',
                GET_STORYSPOT_SEO_SUCCESS: 'get_storyspots_seo_success',
                GET_STORYSPOT_SEO_ERROR: 'get_storyspots_seo_error',
            },
            ADMIN: {
                GET_ALL_STORYSPOTS_REQUEST: 'GET_ALL_STORYSPOTS_REQUEST',
                GET_ALL_STORYSPOTS_SUCCESS: 'GET_ALL_STORYSPOTS_SUCCESS',
                GET_ALL_STORYSPOTS_ERROR: 'GET_ALL_STORYSPOTS_ERROR',
                GET_STORYSPOTS_DETAILED_REQUEST: 'GET_STORYSPOTS_DETAILED_REQUEST',
                GET_STORYSPOTS_DETAILED_SUCCESS: 'GET_STORYSPOTS_DETAILED_SUCCESS',
                GET_STORYSPOTS_DETAILED_ERROR: 'GET_STORYSPOTS_DETAILED_ERROR',
                GET_ALL_USERS_REQUESTS: 'GET_ALL_USERS_REQUESTS',
                GET_ALL_USERS_SUCCESS: 'GET_ALL_USERS_SUCCESS',
                GET_SPECIFIC_USER_REQUESTS: 'GET_SPECIFIC_USER_REQUESTS',
                GET_SPECIFIC_USER_SUCCESS: 'GET_SPECIFIC_USER_SUCCESS',
                GET_REPORTS_REQUEST: "GET_REPORTS_REQUEST",
                GET_REPORTS_ERROR: "GET_REPORTS_ERROR",
                GET_REPORTS_SUCCESS: "GET_REPORTS_SUCCESS",
                GET_STORYSPOTTERS_REQUESTS: "GET_STORYSPOTTERS_REQUESTS",
                GET_STORYSPOTTERS_ERROR: "GET_STORYSPOTTERS_ERROR",
                GET_STORYSPOTTERS_SUCCESS: "GET_STORYSPOTTERS_SUCCESS",
                UPDATE_USER_REQUEST: "UPDATE_USER_REQUEST",
                UPDATE_USER_SUCCESS: "UPDATE_USER_SUCCESS",
                UPDATE_USER_ERROR: "UPDATE_USER_ERROR",
            },
            ORGANIZATION: {
                GET_ONE_REQUEST: "GET_ONE_REQUEST",
                GET_ALL_REQUEST: "GET_ALL_REQUEST",
                GET_ALL_SUCCESS: "GET_ALL_SUCCESS",
                GET_ALL_ERROR: "GET_ALL_ERROR",
                ADD_USERS_TO_ORG_REQUEST: "ADD_USERS_TO_ORG_REQUEST",
                ADD_USERS_TO_ORG_ERROR: "ADD_USERS_TO_ORG_ERROR",
                ADD_USERS_TO_ORG_SUCCESS: "ADD_USERS_TO_ORG_SUCCESS",
            }

        },
        audioPlayer: {
            SET_AUDIO: 'set_player_audio',
            DISMISS_AUDIO: 'dismiss_player_audio',
        }
    },
    api: {
        baseApiEndpoint: "api/v1/",
        baseApiEndpointv2: "api/v1.1/",
        authEndpoint: "auth/",
        userEndpoint: "user/",
        storyspotEndpoint: "storyspot/",
        recommendationEndpoint: "recommendations/",
        discoverEndpoint: "recommendations/discover",
        seoEndpoint: "seo/",
        organizationEndpoint: "organization/",
        reports: "report/",
        storyspotters: "admin/storyspotters/",
        categoryEndpoint: "category/",
        staticDataEndpoint: "static/",
        placeEndpoint: "place/",
        passwordResetEndpoint: "reset-password/",
        verifyEmail: "verify-email/",
        forgotPassword: "forgot-password/",
        feedback: "send-feedback/",
        path: {
            profile: "profile/",
            storyspots: "storyspots/",
            register: "register/"
        },
        organization: {
            statsEndpoint: "organization/stats/",
            storyspotsEndpoint: "organization/storyspot/",
            authEndpoint: "organization/auth/",
            profileEndpoint: "organization/profile/",
        },
        analytics: {
            stats: "/stats",
            event: "/event",
            storyspot: "/stats/storyspot",
            author: "/stats/author"
        },
        admin: {
            stats: '/admin/analytics/stats',
            updateUser: '/admin/updateUser',
        }
    },
    storage: {
        organizaiton: "orgKey",
        user: "userKey",
        token: "tokenKey",
        refreshToken: "refreshToken"
    },
    session: {
        session: 'sessionKey',
        disclaimer: 'disclaimerKey'
    },
    default: {
        image:
            "https://storage.googleapis.com/story-spot-staging-public/storyspot_default_image_512.png"
    },
};
