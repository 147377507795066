<template>
  <div>
    <div class="wrapper">
      <div class="main-storyspot">
        <div class="md-layout text-left">
          <div
              class="md-layout-item md-size-20 md-xlarge-size-20 md-large-size-20"
          >
            <img
                :src="storyspot.displayImagePlaceholder"
                alt="Rounded Image"
                class="rounded img-fluid displayImage"
            />
          </div>
          <div
              class="md-layout-item md-size-70"
          >
            <div class="text-container">

              <div class="blockquote large-paragraph">
                <small>
                  <badge :style="`background-color: ${storyspot.category.color}`">{{ storyspot.category.name }}</badge>
                  <strong>• </strong> {{ storyspot.durationTime }}
                  <strong> • </strong> {{ storyspot.langString }}
                  <strong> • </strong> {{storyspot.currentDateString }}
                </small>
              </div>
              <h4 class="title">
                <div v-if="!storyspot.isActive" class="not-active">
                  <md-icon class="icon">visibility_off</md-icon>
                  <md-tooltip md-delay="300" md-direction="right">This storyspot is {{ storyspot.status.name }} and not
                    visible to other users
                  </md-tooltip>
                </div>
                <router-link :to="toSpotBasePath + storyspot.id">
                  <span class="tim-note">{{ storyspot.name }}</span>
                </router-link>
              </h4>
              <p class="text-description">
                {{ storyspot.description }}
              </p>
              <div class="blockquote undefined sub-menu">
                <ul>
                  <li>
                    <div class="mini-list">
                      <md-icon class="icon">headphones</md-icon>
                      <p>{{ storyspot.listens }} Listens</p>
                    </div>
                  </li>
                  <li>
                    <div class="mini-list">
                      <md-icon class="icon">favorite</md-icon>
                      <p>{{ storyspot.likes }} Likes</p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div>
            <li class="md-list-item">
              <a
                  href="javascript:void(0)"
                  class="md-list-item-router md-list-item-container md-button-clean dropdown"
              >

                <drop-down direction="down">
                  <md-icon>more_vert</md-icon>
                  <ul class="dropdown-menu dropdown-menu-right" style="z-index: 9999">
                    <li class="dropdown-header">Options</li>
                    <li>
                      <a class="dropdown-item" @click="copyLink">
                        <md-icon>link</md-icon>
                        <p>Get link</p>
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" @click="showModal">
                        <md-icon>qr_code_2</md-icon>
                        <p>Create QR-code</p>
                      </a>
                    </li>
                    <li v-if="showEdit" class="dropdown-header">Settings</li>
                    <li v-if="showEdit">
                      <router-link class="dropdown-item" :to="'/edit-storyspot/' + this.storyspot.id">
                        <md-icon>edit</md-icon>
                        <p>Edit storyspot</p>
                      </router-link>
                    </li>
                  </ul>
                </drop-down>
              </a>
            </li>
          </div>
        </div>
      </div>
    </div>
    <QrCode :storyspot="storyspot" :show="showQrModal" :close="closeModal"/>
  </div>
</template>
<script>
import Badge from "../Badge";
import Vue from "vue";
import QrCode from "../QrCode";
import sharedConfig from "../../shared-config"
import {copyString} from "../../utils/general-utils";

export default {
  components: {
    QrCode,
    Badge
  },
  props: {
    storyspot: {
      type: Object,
      default: null
    },
    toSpotBasePath: {
      type: String,
      default: '/our-storyspots/'
    },
    showEdit: {
      default: true,
    }
  },
  data() {
    return {
      searchInput: null,
      showQrModal: false,
    }
  },
  methods: {
    playAudio() {
      this.$store.dispatch(sharedConfig.store.audioPlayer.SET_AUDIO, this.storyspot.audio)
    },
    showModal() {
      this.showQrModal = true;
    },
    closeModal() {
      this.showQrModal = false;
    },
    copyLink() {
      let link = "https://app.storyspot.se/share/s" + this.storyspot.id;
      copyString(link);
      const instance = Vue.$toast.open({
        message: "Link copied successfully",
        type: "success",
        position: "top-right"
      });
    },
  }
};
</script>
<style lang="scss" scoped>


.wrapper {
  margin-top: 28px;
}

.blockquote {
  font-size: 18px;
}

.blockquote strong {
  margin: 0 5px 0 5px;
}

.text-container {
  margin-top: 0;
  padding: 0;
}

.text-container .p {
  text-overflow: ellipsis;
}

.title {
  margin-top: 0 !important;
  margin-bottom: 5px !important;
  padding: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
  cursor: pointer;
  color: #3C4858 !important;
}

.md-theme-default a:not(.md-button) {
  color: unset !important;
}


.center {
  margin: auto;
  width: 50%;
}

.icon {
  font-size: 18px !important;
}

.text-description {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

.play-icon {
  position: absolute !important;
  right: 30px;
}


.dropdown-item:hover {
  .md-icon {
    color: white !important;
  }

  p {
    color: white !important;
  }
}

.md-tooltip {
  border-radius: unset;
  color: white !important;
  background-color: #989898 !important;
  font-weight: unset;
  box-shadow: unset;
  max-width: unset;
}

.not-active {
  float: left;
  padding-right: 8px
}

.displayImage {
  object-fit: contain !important;
  // Just to fill width
  width: 200px;
}

.md-list-item a .material-icons, .md-list-item a .md-icon, .md-list-item a .fab, .md-list-item a .fa {
  font-size: 25px !important;
}

.mini-list {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
  p {
    padding-top: 10px;
  }
}
</style>