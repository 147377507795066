import storage from "../../../shared/utils/localstorage-handler";
import sharedConfig from "../../../shared/shared-config";
import authService from "../../../shared/services/user-service"
import router from "../router";
import {User} from "../../../shared/models/CurrentUser.model";
import Vue from "vue";
import {shallowEqual} from "../../../shared/utils/general-utils";
import userService from "../../../shared/services/user-service";

const state = {
    isLoadingLogin: false,
    signInError: '',
    isAuthenticated: storage.get(sharedConfig.storage.token) != null,
    user:
        storage.get(sharedConfig.storage.user) != null
            ? storage.get(sharedConfig.storage.user)
            : null,
    update: {
        hasUpdatableValue: false,
        isReady: false,
        profile: {
            fullName: "",
            picture: null,
            description: "",
            showEmail: false,
            socialLinks: null,
            removeImage: null,
            profileImage: null
        },
        original: {
            fullName: null,
            picture: null,
            description: null,
            showEmail: null,
            socialLinks: null,
            removeImage: null,
            profileImage: null
        },
        validators: {
            fullName: null,
            picture: null,
            description: null,
            showEmail: null,
            removeImage: null,
        },
    },
    registerError: '',
}

const getters = {
    isAuthenticated(state) {
        return state.isAuthenticated;
    },
    getRegisteredError(state) {
        return state.registerError;
    },
    isAdmin(state) {
        return state.user && state.user.role && state.user.role.toLowerCase() === "administrator";
    },
    isPartOfOrganization(state) {
        return state.user.organization != null;
    },
    getLoginDetails(state) {
        return {
            isLoading: state.isLoadingLogin,
            error: state.signInError,
        }
    },
    getCurrentUser(state) {
        return state.user;
    },
    signInError(state) {
        return state.signInError;
    },
    canUpdateProfile(state) {
        return state.update.hasUpdatableValue
    },
    getUpdateProfile(state) {
        return state.update.profile;
    },
    profileValidators(state) {
        return state.update.validators;
    },
    updateProfileViewReady(state) {
        return state.update.isReady;
    },
}

const actions = {
    [sharedConfig.store.actions.AUTH.LOGIN_REQUEST]: (
        {commit, dispatch},
        credentials
    ) => {
        commit(sharedConfig.store.actions.AUTH.LOGIN_REQUEST);
        authService
            .loginEmail(credentials.email, credentials.password)
            .then(resp => {
                commit(sharedConfig.store.actions.AUTH.LOGIN_SUCCESS, resp);
                dispatch(sharedConfig.store.actions.STATIC.GET_STATIC_DATA_REQUEST);
                dispatch(sharedConfig.store.actions.STORYSPOT.GET_USER_STORYSPOTS_REQUEST);
            })
            .catch(error => {
                commit(sharedConfig.store.actions.AUTH.LOGIN_ERROR, error.response);
            });
    },
    [sharedConfig.store.actions.AUTH.GOOGLE_LOGIN_REQUEST]: (
        {commit, dispatch},
        gToken
    ) => {
        commit(sharedConfig.store.actions.AUTH.LOGIN_REQUEST);
        authService
            .loginGoogle(gToken)
            .then(resp => {
                commit(sharedConfig.store.actions.AUTH.LOGIN_SUCCESS, resp);
                dispatch(sharedConfig.store.actions.STATIC.GET_STATIC_DATA_REQUEST);
                dispatch(sharedConfig.store.actions.STORYSPOT.GET_USER_STORYSPOTS_REQUEST);
            })
            .catch(error => {
                commit(sharedConfig.store.actions.AUTH.LOGIN_ERROR, error.response);
            });
    },
    [sharedConfig.store.actions.AUTH.APPLE_LOGIN_REQUEST]: (
        {commit, dispatch},
        aToken
    ) => {
        commit(sharedConfig.store.actions.AUTH.LOGIN_REQUEST);
        authService
            .loginApple(aToken)
            .then(resp => {
                commit(sharedConfig.store.actions.AUTH.LOGIN_SUCCESS, resp);
                dispatch(sharedConfig.store.actions.STATIC.GET_STATIC_DATA_REQUEST);
                dispatch(sharedConfig.store.actions.STORYSPOT.GET_USER_STORYSPOTS_REQUEST);
            })
            .catch(error => {
                commit(sharedConfig.store.actions.AUTH.LOGIN_ERROR, error.response);
            });
    },
    [sharedConfig.store.actions.AUTH.LOGOUT_REQUEST]: ({commit, dispatch}) => {
        commit(sharedConfig.store.actions.AUTH.LOGOUT_SUCCESS);
    },
    [sharedConfig.store.profile.UPDATABLE_VALUES]: ({commit, state}) => {
        let update = {};
        for (let i = 0; i < Object.keys(state.update.profile).length; i++) {
            const key = Object.keys(state.update.profile)[i];
            const equal = shallowEqual(state.update.profile[key], state.update.original[key]);
            if (!equal) {
                update[key] = state.update.profile[key];
            }
        }
        // Remove imgaes only exists as key on the new object, eg remove if zero
        if (update.removeImage && update.removeImage.length === 0) {
            delete update.removeImage;
        }
        if (Object.keys(update).length > 0) {
            state.update.hasUpdatableValue = true;
            const formData = new FormData();
            for (let i = 0; i < Object.keys(update).length; i++) {
                formData.append(Object.keys(update)[i], update[Object.keys(update)[i]]);
            }
            return formData;
        } else {
            state.update.hasUpdatableValue = false;
            return null;
        }

    },
    [sharedConfig.store.profile.SET_BASE]: ({commit, state}) => {
        for (let i = 0; i < Object.keys(state.update.original).length; i++) {
            let key = Object.keys(state.update.original)[i]
            if (key in state.user) {
                state.update.profile[key] = JSON.parse(JSON.stringify(state.user[key]));
                state.update.original[key] = JSON.parse(JSON.stringify(state.user[key]));
            }
            state.update.isReady = true;
        }
    },
    [sharedConfig.store.profile.SET]: ({dispatch, commit, state}, obj) => {
        if (obj.key in state.update.profile) {
            commit(sharedConfig.store.profile.SET_KEY_VALUE, obj);
            dispatch(sharedConfig.store.profile.VALIDATE_FIELD, obj.key);
            dispatch(sharedConfig.store.profile.UPDATABLE_VALUES);
        }
    },
    [sharedConfig.store.profile.VALIDATE_FIELD]: ({commit, state}, key) => {
        if (key === 'fullName') {
            state.update.validators.fullName = validateName(state.update.profile.fullName);
        }
    },
    [sharedConfig.store.profile.UPDATE]: ({dispatch, commit, state}, data) => {
        return new Promise((resolve, reject) => {
            userService.updateProfile(data)
                .then(resp => {
                    commit(sharedConfig.store.profile.UPDATE_SUCCESS, resp, resp.data);
                    resolve();
                })
                .catch(e => {
                    commit(sharedConfig.store.profile.UPDATE, e);
                    reject(e);
                });
        });
    },
    [sharedConfig.store.actions.AUTH.AUTH_REGISTER_REQUEST]: (
        { commit, dispatch },
        credentials
    ) => {
        commit(sharedConfig.store.actions.AUTH.AUTH_REGISTER_REQUEST);
        console.log(credentials);
        userService
            .registerEmailPassword(
                credentials.username,
                credentials.email,
                credentials.password
            )
            .then(resp => {
                console.log(resp);
                commit(sharedConfig.store.actions.AUTH.AUTH_REGISTER_SUCCESS, resp);
            })
            .catch(error => {
                commit(sharedConfig.store.actions.AUTH.AUTH_REGISTER_ERROR, error);
            });
    },
}

const mutations = {
    [sharedConfig.store.actions.AUTH.AUTH_REGISTER_REQUEST]: state => {
        state.registerError = "";
    },
    [sharedConfig.store.actions.AUTH.AUTH_REGISTER_SUCCESS]: (state, resp) => {
        state.isAuthenticated = true;
        state.token = storage.get(sharedConfig.storage.token);
        storage.set(sharedConfig.storage.user, resp.data.user);
        state.user = new User(resp.data.user);
        router.push("/my-storyspots");
        //this.$analytics.setUserId(state.user.id);
    },
    [sharedConfig.store.actions.AUTH.AUTH_REGISTER_ERROR]: (state, error) => {
        state.isAuthenticated = false;
        state.registerError =
            error?.response?.message != null
                ? error.response.message
                : "There was an error trying to register.";
    },
    [sharedConfig.store.actions.AUTH.LOGIN_REQUEST]: (state, resp) => {
        // setting loading
        state.isLoadingLogin = true;
        // resetting if any error message
        state.signInError = "";
    },
    [sharedConfig.store.actions.AUTH.LOGIN_SUCCESS]: (state, resp) => {
        // Is set in storage from interceptor
        state.token = storage.get(sharedConfig.storage.token);
        storage.set(sharedConfig.storage.user, resp.data.user);

        state.user = new User(resp.data.user);
        state.isAuthenticated = true;

        state.isLoadingLogin = false;

        router.push("/my-storyspots");

        const instance = Vue.$toast.open({
            message: "Welcome " + (state.user && state.user.fullName ? state.user.fullName : state.user.username),
            type: "success",
            position: "bottom"
        });

        // this.$analytics.setUserId(state.user.id);
    },
    [sharedConfig.store.actions.AUTH.LOGIN_ERROR]: (state, error) => {
        state.isAuthenticated = false;
        state.isLoadingLogin = false;
        state.signInError =
            error?.response?.message != null
                ? error.response.message
                : "There was an error signing in.";
        console.log(state.signInError);
    },
    [sharedConfig.store.actions.AUTH.LOGOUT_SUCCESS]: state => {
        storage.delete(sharedConfig.storage.token);
        storage.delete(sharedConfig.storage.refreshToken);
        storage.delete(sharedConfig.storage.user);
        state.isAuthenticated = false;
        router.push("/");
        //this.$analytics.signOut();
    },
    [sharedConfig.store.profile.SET_KEY_VALUE]: (state, obj) => {
        if (obj.key === 'profileImage') {
            if (obj.value) {
                state.update.profile.picture = URL.createObjectURL(obj.value);
            } else {
                state.update.profile.picture = null;
            }
        }
        state.update.profile[obj.key] = obj.value;
    },
    [sharedConfig.store.profile.UPDATE_ERROR]: (state, object) => {
        // HANDLE ERROR
        console.log('update successful', object);
    },
    [sharedConfig.store.profile.UPDATE_SUCCESS]: (state, data) => {
        let newUser =  new User(data.data);
        for (let i = 0; i < Object.keys(state.user).length; i++) {
            let key = Object.keys(state.user)[i];
            if (key !== 'role' && key in newUser) {
                state.user[key] = newUser[key];
            }
        }
        storage.set(sharedConfig.storage.user, JSON.parse(JSON.stringify(state.user)));
    },
}

export default {
    state,
    getters,
    actions,
    mutations
};



const validateName = function (name) {
    if (!name) {
        return "Name is required"
    } else if (name.length < 5) {
        return "Name needs to be at least 5 characters long"
    }
    return null;
}