<template>
  <div class="main-content">
    <div class="wrapper">
      <div class="main main-raised">
        <div class="section">
          <div class="container text-center">
            <div
                class="md-layout-item md-size-100"
            >
              <div v-if="!isLoading && storyspotPlace">
                <img
                    class="logo-img"
                    src="../assets/logo_480.png"
                    alt="Storyspot logo"
                />
            <h1 style="display: flex; justify-content: center">
              <div v-if="!this.storyspotPlace.storyspot.isActive" class="not-active">
                <md-icon class="icon">visibility_off</md-icon>
                <md-tooltip md-delay="300" md-direction="right">This storyspot is
                  {{ this.storyspotPlace.storyspot.status.name }} and not
                  visible to other users
                </md-tooltip>
              </div>
              {{ storyspotPlace.storyspot.name }}</h1>
                <h3 style="display: flex; justify-content: center">Lyssna i storyspot appen nu</h3>
          </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import sharedConfig from "../../../shared/shared-config"
import ImageSlider from "../../../shared/components/ImageSlider";
export default {
  components: {
    ImageSlider,
  },
  data() {
    return {
      storyspotPlace: null,
      id: null,
      url: null,
      cId: "",
      isLoading: true,
    };
  },
  metaInfo() {
    return {
      title: "Storyspot - Tell local, share global.",
      meta: [
        {
          property: "title",
          content: this.getMetaData.title
        },
        {
          property: "og:title",
          content: this.getMetaData.title
        },
        {
          name: "og:description",
          content: this.getMetaData.description
        },
        {
          name: "description",
          content: this.getMetaData.description
        },
        {property: "og:image", content: this.image}
      ]
    };
  },
  mounted() {
    // if id starts with a, its author aXXXXXX where X is author id,
    // if id starts with s, its storyspot sXXXXXX where X is storyspot id.
    this.cId = this.$route.params.id;
    if (this.cId && this.cId.startsWith("s")) {
      this.id = this.$route.params.id.substring(1);
      this.$store.dispatch(sharedConfig.store.actions.STORYSPOT.GET_STORYSPOT_DETAILED_REQUEST, this.id).then((data) => {
        this.storyspotPlace = data;
        this.isLoading = false;
      })
          .catch(e => {
            console.log(e);
          });
      window.location.href = "storyspot://ss?id=" + this.id;
    } else if (this.cId && this.cId.startsWith("a")) {
      // @ts-ignore
      this.id = this.$route.params.id.substring(1);
      // @ts-ignore
      window.location.href = "storyspot://author?id=" + this.id;
    } else if (this.cId && this.cId.startsWith("c")) {
      this.id = this.$route.params.id.substring(1);
      window.location.href = "storyspot://c?id=" + this.id;
    } else {
      const storyspotId = this.$route.params.id;
      // @ts-ignore
      window.location.href = "storyspot://ss?id=" + storyspotId;
    }

    setTimeout(() => this.redirect(), 1200);
  },
  methods: {
    redirect() {
      const iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
      if (iOS) {
        window.location.href = "itms://apps.apple.com/us/app/storyspot-discover-the-world/id1090760665";
      } else {
        window.location.href = "https://play.google.com/store/apps/details?id=se.storyspot.android.StorySpot";
      }
    }
  },
  computed: {
    image() {
      const s = this.storyspotPlace;
      if (this.story != null) return s.storyspot.displayImage();
      else return sharedConfig.default.image;
    },
    getMetaData() {
      if (this.cId && this.cId.startsWith("a")) {
        return {
          title: "Author",
          description: "Storyspot author"
        };
      } else if (this.cId && this.cId.startsWith("s")) {
        return {
          title: this.story?.storyspot?.name ?? "Storyspot",
          description: this.story?.storyspot?.description ?? "Description"
        };
      } else if (this.cId && this.cId.startsWith("c")) {
        return {
          title: "Storyspot - Shared",
          description: "Shared storyspot link"
        };
      } else {
        return {
          title: "Storyspot",
          description: "Description"
        };
      }
    }
  }
}
</script>
<style>
.logo-img {
  max-width: 30%;
  margin: auto !important;
}
</style>
