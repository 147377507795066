import sharedConfig from "../../../shared/shared-config";
import baseAPI from "../../../shared/services/base-api-service"
import {shallowEqual} from "../../../shared/utils/general-utils";

const state = {
    isUploading: false,
    error: null,
    hasUpdatableValue: false,
    storyspot: {
        name: null,
        description: null,
        language: null,
        audio: null,
        category: null,
        images: null,
        position: null,
        tags: null,
        status: null,
        removeImage: [],
    },
    originalStorypot: {
        name: null,
        description: null,
        language: null,
        category: null,
        images: null,
        position: null,
        tags: null,
        status: null,
    },
    validators: {
        name: null,
        description: null,
        lang: null,
        category: null,
        images: null,
        audio: null,
        position: null,
        tags: null,
        status: null,
        originalAudio: null,
        removeImage: null,
    },
    errorMessage: null,
}

const getters = {
    editStoryspot(state) {
        return state.storyspot;
    },
    canUpdateEditStoryspot(state) {
        return state.hasUpdatableValue
    },
    editStoryspotToFormData(state) {
    },
    isEditValid(state) {
        for (let i=0; i<Object.keys(state.validators).length; i++) {
            // empty all previus errors
            if (state.validators[Object.keys(state.validators)[i]] != null) {
                return false;
            }
        }
        return true;
    },
    editValidators(state) {
        return state.validators;
    }
}

const actions =  {
    [sharedConfig.store.editSpot.SET_BASE]: async ({dispatch, commit, state}, storyspotPlace) => {
        commit(sharedConfig.store.editSpot.SET_BASE, storyspotPlace);
    },
    [sharedConfig.store.editSpot.SET]: ({dispatch, commit, state}, obj) => {
        if (obj.key in state.storyspot) {
            commit(sharedConfig.store.editSpot.SET_KEY_VALUE, obj);
            dispatch(sharedConfig.store.editSpot.VALIDATE_FIELD, obj.key);
            dispatch(sharedConfig.store.editSpot.UPDATABLE_VALUES);
        }
    },
    [sharedConfig.store.editSpot.VALIDATE_FIELD]: ({commit, state}, key) => {
        if (key === 'name') {
            state.validators.name = validateName(state.storyspot.name);
        }
        if (key === 'description') {
            state.validators.description = validateDescription(state.storyspot.description);
        }
        if (key === 'lang') {
            state.validators.lang = validateLang(state.storyspot.lang);
        }
        if (key === 'category') {
            state.validators.category = validateCategory(state.storyspot.category)
        }
        if (key === 'audio') {
            state.validators.audio = validateAudio(state.storyspot.audio, state.storyspot.duration)
        }
        if (key === 'position') {
            state.validators.position = validatePosition(state.storyspot.position)
        }
        if (key === 'position') {
            state.validators.position = validatePosition(state.storyspot.position)
        }
        if (key === 'visability') {
            state.validators.status = validateStatus(state.storyspot.status)
        }
    },
    [sharedConfig.store.editSpot.VALIDATE]: ({commit, state}) => {
        for (let i=0; i<Object.keys(state.validators).length; i++) {
            // empty all previous errors
            state.validators[Object.keys(state.validators)[i]] = null;
        }
        state.validators.name = validateName(state.storyspot.name)
        state.validators.description = validateDescription(state.storyspot.description);
        state.validators.lang = validateLang(state.storyspot.lang);
        state.validators.category = validateCategory(state.storyspot.category);
        state.validators.audio = validateAudio(state.storyspot.audio, state.storyspot.duration);
        state.validators.position = validatePosition(state.storyspot.position);
        state.validators.status = validateStatus(state.storyspot.status);
    },
    [sharedConfig.store.editSpot.UPDATABLE_VALUES]: ({commit, state}) => {
        let update = {};
        for (let i = 0; i < Object.keys(state.storyspot).length; i++) {
            const key = Object.keys(state.storyspot)[i];
            console.log(key);
            if (key !== 'audio') {
                const equal = shallowEqual(state.storyspot[key], state.originalStorypot[key]);
                if (!equal) {
                    if (key === 'language' || key === 'category' || key === 'status') {
                        update[key] = state.storyspot[key].id;
                    } else if (key === 'position') {
                        let position = {
                            lat: state.storyspot[key].lat,
                            lon: state.storyspot[key].lng
                        };
                        update[key] = JSON.stringify(position);
                    } else if (key === 'images') {
                        const imgs = state.storyspot[key];
                        if (imgs && imgs.length > 0) {
                            update.images = [];
                        }
                        for (let i = 0; i < imgs.length; i++) {
                            // If image has ID, it already exists
                            if (!imgs[i].id) {
                                update.images.push(imgs[i]);
                            }
                        }
                    } else {
                        update[key] = state.storyspot[key];
                    }
                }
            }
        }
        // Remove imgaes only exists as key on the new object, eg remove if zero
        if (update.removeImage && update.removeImage.length === 0) {
            delete update.removeImage;
        }
        // Images might be formatted a little bit different, so making sure to cleared them if they have an id, eg exists
        if (update && update.images && update.images.length === 0) {
            delete update.images;
        }
        if (Object.keys(update).length > 0) {
            state.hasUpdatableValue = true;
            const formData = new FormData();
            for (let i = 0; i < Object.keys(update).length; i++) {
                const key = Object.keys(update)[i];
                let value = update[Object.keys(update)[i]];
                if (key === 'images') {
                    for (let i=0; i<value.length; i++) {
                        let img = value[i];
                        formData.append(key, img);
                    }
                } else {
                    formData.append(key, value);
                }
            }
            return formData;
        }  else {
            state.hasUpdatableValue = false;
            return null;
        }

    },
    [sharedConfig.store.editSpot.UPDATE]: ({commit, state}, update) => {
        return new Promise((resolve, reject) => {
            baseAPI
                .putFormData(sharedConfig.api.storyspotEndpoint + update.id, update.data)
                .then(resp => {
                    commit(sharedConfig.store.editSpot.UPDATE_SUCCESS, resp, update.data);
                    resolve();
                })
                .catch(e => {
                    commit(sharedConfig.store.editSpot.UPDATE_ERROR, e);
                    reject(e);
                });
        });
    }
}

const mutations = {
    [sharedConfig.store.editSpot.SET_BASE]: (state, data) => {
        for (let i = 0; i < Object.keys(state.storyspot).length; i++) {
            let key = Object.keys(state.storyspot)[i];
            if (key in data.storyspot) {
                state.storyspot[key] = data.storyspot[key];
                state.originalStorypot[key] = JSON.parse(JSON.stringify(data.storyspot))[key];
            } else if (key in data.place) {
                state.storyspot[key] = data.place[key];
                state.originalStorypot[key] = JSON.parse(JSON.stringify(data.place))[key];
            }
        }
    },
    [sharedConfig.store.editSpot.SET_KEY_VALUE]: (state, obj) => {
        state.storyspot[obj.key] = obj.value;
    },
    [sharedConfig.store.editSpot.UPDATE]: (state) => {
        state.isUploading = true;
        state.error = null;
    },
    [sharedConfig.store.editSpot.UPDATE_SUCCESS]: (state, data) => {
        state.isUploading = false;
        clearState(state);
        console.log('success', data)
    },
    [sharedConfig.store.editSpot.UPDATE_ERROR]: (state, error) => {
        state.isUploading = false;
        state.error = error;
        console.log('error', error);
    },
}

export default {
    state,
    getters,
    actions,
    mutations
};

const clearState = function (state) {
    state.error = null;
    state.hasUpdatableValue = false;
    state.storyspot = {
        name: null,
        description: null,
        language: null,
        audio: null,
        category: null,
        images: null,
        position: null,
        tags: null,
        status: null,
        removeImage: [],
    };
    state.originalStorypot = {
        name: null,
        description: null,
        language: null,
        category: null,
        images: null,
        position: null,
        tags: null,
        status: null,
    };
    state.validators = {
        name: null,
        description: null,
        lang: null,
        category: null,
        images: null,
        audio: null,
        position: null,
        tags: null,
        status: null,
        originalAudio: null,
        removeImage: null,
    };
    state.errorMessage = null;
}


const validateName = function (name) {
    if (!name) {
        return "Name is required"
    } else if (name.length < 8) {
        return "Name needs to be at least 8 characters long"
    }
    return null;
}

const validateDescription = function (description) {
    if (!description) {
        return "Description is required"
    } else if (description.length < 10) {
        return "Description needs to be at least 10 characters long"
    }
    return null;
}

const validateCategory = function (category) {
    if (!category || !category.id) {
        return "A category is required";
    }
    return null;
}

const validateLang = function (language) {
    if (!language || !language.id) {
        return "A language is required";
    }
    return null;
}

const validateAudio = function (audio, duration) {
    if (!audio) {
        return "A audio file is required"
    } else if (!duration || duration <= 0) {
        return "Cannot read audio file, try a different format"
    }
    return null;
}

const validatePosition = function (position) {
    if (!position) {
        return "A location is requried";
    } else if (!position.lat || !position.lng) {
        return "There seems to be an error with the location"
    }
    return null;
}

const validateStatus = function (status) {
    if (!status) {
        return "A status is required";
    } else if (!status.id) {
        return "Invalid status";
    }
    return null;
}