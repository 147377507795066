<template>
  <div class="main-content">
    <div class="wrapper">
      <div class="main main-raised">
        <div class="section">
          <div class="container">
            <div class="button-container">
             <router-link to="/create-storyspot">
                <md-button class="md-primary btn-regular">
                  <md-icon>add</md-icon>
                  Create Storyspot
                </md-button>
              </router-link>
            </div>
            <SearchBar @send-searchparameter="didChangeSearch" :storyspotCount="'(' + getStoryspots.length + ')'"/>
            <div v-for="story in getStoryspots" v-bind:key="story.id">
              <StoryspotSmall :storyspot="story" :toSpotBasePath="'/my-storyspots/'" :showEdit="false"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SearchBar from "../../../shared/components/SearchBar";
import StoryspotSmall from "../../../shared/components/cells/StoryspotCell";

export default {
  bodyClass: "My-storyspots",
  components: {
    StoryspotSmall,
    SearchBar
  },
  created() {
    document.title = "My Storyspots - Storyspot";
  },
  data() {
    return {
      searchText: null,
    };
  },
  methods: {
    didChangeSearch(value) {
      this.searchText = value;
    },
  },
  computed: {
    getStoryspots() {
      if (this.searchText) {
        return this.$store.getters.getCurrentUserStoryspots.filter(
            (a) =>
                a.q && a.q.indexOf(this.searchText.toLowerCase()) > -1
        );
      }
      return this.$store.getters.getCurrentUserStoryspots;
    }
  }
};
</script>

<style lang="scss" scoped>

.section {
  // Used cause we want the create storyspot and search header to act as section "headers"
  padding-top: 20px !important;
}

.main {
  min-height: 80vh;
  z-index: 0;
}

.button-container {
  margin-left: auto;
  margin-right: 0;
}

</style>
