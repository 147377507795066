import Vue from "vue";
import Router from "vue-router";

import MyStoryspots from "./views/MyStoryspots.vue";
import CreateStoryspot from "./views/CreateStoryspot.vue";
import MyProfile from "./views/MyProfile.vue";
import Login from "./views/LoginV2";
import Registered from "./views/RegisterUser";
import StoryspotDetails from "./views/StoryspotDetails";
import SeoStoryspot from "./views/SeoResultStoryspot";
import TermsAndCondition from "./views/Static/TermsAndCondition.vue";
import Faq from "./views/Static/FAQ.vue";
import Navigation from "./components/MainNavigation";
import Footer from "../../shared/components/Footer";
import store from "./store/index";
import EditStoryspot from "./views/EditStoryspot";
import Feedback from "./views/Feedback";
import VerifiedEmail from "./views/Static/VerifiedEmail";
import Share from "./views/ShareRedirect";
import ResetPassword from "./views/ResetPassword";
Vue.use(Router);


const ifAuthenticated = (to, from, next) => {
    if (store.getters.isAuthenticated) {
        next();
        return;
    }
    next("/");
};

const ifNotAuthenticated = (to, from, next) => {
    if (!store.getters.isAuthenticated) {
        next();
        return;
    }
    next("/my-storyspots");
};


export default new Router({
    mode: 'history',
    routes: [
        {
            path: "/",
            redirect: "/login",
            mode: 'history',
        },
        {
            path: "/storyspot/:id",
            name: "storyspot",
            components: { default: SeoStoryspot },
        },
        {
            path: "/login",
            name: "Login",
            beforeEnter: ifNotAuthenticated,
            components: { default: Login, header: null},
        },
        {
            path: "/register",
            name: "Register",
            beforeEnter: ifNotAuthenticated,
            components: { default: Registered, header: null},
        },
        {
            path: "/my-storyspots",
            name: "MyStoryspots",
            beforeEnter: ifAuthenticated,
            components: { default: MyStoryspots, header: Navigation, footer: Footer },
        },
        {
            path: "/my-storyspots/:id",
            name: "MyStoryspot",
            beforeEnter: ifAuthenticated,
            components: { default: StoryspotDetails, header: Navigation, footer: Footer },
        },
        {
            path: "/create-storyspot",
            name: "CreateStoryspot",
            beforeEnter: ifAuthenticated,
            components: { default: CreateStoryspot, header: Navigation, footer: Footer },
        },
        {
            path: "/edit-storyspot/:id",
            name: "EditStoryspot",
            beforeEnter: ifAuthenticated,
            components: { default: EditStoryspot, header: Navigation, footer: Footer },
        },
        {
            path: "/my-profile",
            name: "MyProfile",
            beforeEnter: ifAuthenticated,
            components: { default: MyProfile, header: Navigation, footer: Footer },
        },
        {
            path: "/feedback",
            name: "Feedback",
            beforeEnter: ifAuthenticated,
            components: { default: Feedback, header: Navigation, footer: Footer },
        },
        {
            path: "/faq",
            name: "FAQ",
            components: { default: Faq, header: Navigation, footer: Footer },
        },
        {
            path: "/terms-and-conditions",
            name: "TermsAndConditions",
            components: { default: TermsAndCondition, header: Navigation, footer: Footer },
        },
        {
            path: "/verified-email",
            name: "VerifiedEmailSuccess",
            components: { default: VerifiedEmail, header: null},
        },
        {
            path: "/reset-password/:id",
            name: "Reset password",
            components: { default: ResetPassword },
        },
        {
            path: "/share/:id",
            name: "shareNew",
            components: { default: Share, header: null},
        },
        {
            path: "/ss/:id/preview",
            name: "ShareOld",
            components: { default: Share, header: null},
        },
    ],
    scrollBehavior: to => {
        if (to.hash) {
            return { selector: to.hash };
        } else {
            return { x: 0, y: 0 };
        }
    }
});
